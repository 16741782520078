<template>
  <div>
    <a-modal
      width="1000px"
      v-model="isShow"
      centered
      :mask-closable="false"
      title="订单详情"
    >
      <template slot="footer">
        <a-button class="ant-btn-primary" @click="closeModal">
          关闭
        </a-button>
      </template>
      <a-row>
        <a-col :span="colSpan">
          <a-descriptions
            title="基本信息"
            :column="1"
            class="iotplt-descriptions"
          >
            <a-descriptions-item label="客户名称">
              {{ data.agent_name }}
            </a-descriptions-item>

            <a-descriptions-item label="订单编号">
              {{ data.order_no }}
            </a-descriptions-item>

            <a-descriptions-item label="项目数量">
              {{ data.items_count }}
            </a-descriptions-item>

            <a-descriptions-item label="订单状态">
              {{ data.status }}
            </a-descriptions-item>

            <a-descriptions-item label="创建时间">
              {{ data.created_at }}
            </a-descriptions-item>

            <a-descriptions-item label="订单备注">
              {{ data.remark }}
            </a-descriptions-item>

            <a-descriptions-item label="购卡协议">
              <a :href="agreement.resource_url" target="_blank">
                {{ agreement.name }}
              </a>
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="colSpan">
          <a-descriptions
            title="订单金额"
            :column="1"
            class="iotplt-descriptions iotplt-descriptions-max-6"
          >
            <a-descriptions-item label="总卡数(张)">
              {{ data.card_count }}
            </a-descriptions-item>

            <a-descriptions-item label="订单金额(元)">
              {{ data.order_fee | formatFloat }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>

        <a-col :span="colSpan">
          <a-descriptions
            title="收货信息"
            :column="1"
            class="iotplt-descriptions"
          >
            <a-descriptions-item label="收货方式">
              {{ data.pickup_way }}
            </a-descriptions-item>

            <a-descriptions-item label="收货人" v-if="isPost">
              {{ data.receiver }}
            </a-descriptions-item>

            <a-descriptions-item label="联系方式" v-if="isPost">
              {{ data.phone_number }}
            </a-descriptions-item>

            <a-descriptions-item label="所属地区" v-if="isPost">
              {{ data.region }}
            </a-descriptions-item>

            <a-descriptions-item label="地区邮编" v-if="isPost">
              {{ data.post_code }}
            </a-descriptions-item>

            <a-descriptions-item label="详细地址" v-if="isPost">
              {{ data.address_detail }}
            </a-descriptions-item>

          </a-descriptions>
        </a-col>
        <a-col :span="colSpan" v-if="isShowDeliveryInfo">
          <a-descriptions title="发货信息" :column="1">
            <a-descriptions-item label="快递公司" v-if="isPost">
              {{ data.express_company }}
            </a-descriptions-item>

            <a-descriptions-item label="快递单号" v-if="isPost">
              {{ data.express_no }}
            </a-descriptions-item>

            <a-descriptions-item label="发货时间" v-if="isPost">
              {{ data.delivery_time }}
            </a-descriptions-item>

            <a-descriptions-item label="提货人" v-if="isSelfTake">
              {{ data.self_take_name }}
            </a-descriptions-item>

            <a-descriptions-item label="联系方式" v-if="isSelfTake">
              {{ data.contact_way }}
            </a-descriptions-item>

            <a-descriptions-item label="提货时间" v-if="isSelfTake">
              {{ data.delivery_time }}
            </a-descriptions-item>
          </a-descriptions>
        </a-col>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import { findAgentPurchaseOrder } from '@/api/agent_purchase_order'
export default {
  name: 'ShowAgentPurchaseOrder',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      agreement: {},
      colSpan: 8,
      isSelfTake: false,
      isPost: false,
      isShowDeliveryInfo: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    // 获取采购订单信息
    fetchData() {
      findAgentPurchaseOrder(this.id).then((res) => {
        this.data = res.data
        this.agreement = res.data.agreement
        this.agreement.name = `《${this.agreement.name}》`
        this.isPost = this.data.pickup_way_slug === 'post'
        this.isSelfTake = this.data.pickup_way_slug === 'self_take'
        if (['delivered', 'finished'].includes(this.data.status_slug)) {
          this.isShowDeliveryInfo = true
          this.colSpan = 6
        }
      })
    },

    closeModal() {
      this.isShow = false
    }
  }
}
</script>
